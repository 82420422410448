import loadable from '@loadable/component';
import React, { useState, useEffect } from 'react';
import ym from 'react-yandex-metrika';
import styled from 'styled-components';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  Field,
  Form,
  Formik,
} from 'formik';
import {
  useMutation, useQuery,
} from '@apollo/client';
import { useAlert } from 'react-alert';
import { Link, useHistory } from 'react-router-dom';
import _ from 'lodash';
import Cookies from 'universal-cookie';
import * as yup from 'yup';

import config from '../config';
import {
  GQL_GET_ME,
  GQL_LOGIN,
} from '../graphql/queries';
import {
  ACTIVE,
  MAIN_GRAY,
} from '../helpers/colors';
import {
  Auth,
  Authorization,
  Eye,
  H3,
  H4,
  Help,
  StyledForm,
} from '../helpers/authStyle';
import { yEvent } from '../components/Tracker';
import { ContentContainer } from '../helpers/mainStyles';
import ArtField from '../components/forms/ArtField';
import ResendActivation from '../components/ResendActivation';
import { useTranslation } from '../components/hooks/useTranslation';

const Icon = loadable(() => import('../components/Icon'));
const MetaTags = loadable(() => import('../components/MetaTags'));

const Login = () => {
  const history = useHistory();
  const alert = useAlert();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [mainPass, setPass] = useState(true);
  const [resend, setResend] = useState(false);
  const [me, setMe] = useState({});
  const cookies = new Cookies();

  const { data: dataMe } = useQuery(GQL_GET_ME, { fetchPolicy: 'cache-and-network' });

  useEffect(() => {
    if (_.get(dataMe, 'me._id')) {
      setMe(_.get(dataMe, 'me'));
    }
  }, [dataMe]);

  const [login] = useMutation(GQL_LOGIN);

  useEffect(() => () => {
    setLoading(false);
    setPass(true);
    ym('hit', '/login');
  }, []);

  useEffect(() => {
    if (_.get(me, '_id')) {
      const goto = cookies.get('goto');
      if (goto) {
        cookies.remove('goto');
        history.push(goto);
      } else {
        history.push('/');
      }
    }
  }, [_.get(me, '_id', false)]);

  const doneResend = () => {
    setResend(false);
    history.push('/');
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setLoading(true);
    const response = await login({
      variables: values,
      refetchQueries: [
        {
          query: GQL_GET_ME,
        },
      ],
    });
    const error = _.get(response, 'errors.0', {});
    if (!_.isEmpty(error)) {
      if (_.get(error, 'extensions.code') === '401') {
        setResend(_.get(error, 'extensions.exception.email'));
        setLoading(false);
        return false;
      }
      alert.show(error.message, { type: 'danger' });
      setSubmitting(false);
      setErrors({
        username: t('error_username', { ns: 'login' }),
        password: t('error_password', { ns: 'login' }),
      });
    }
    setLoading(false);
    return true;
  };

  const metrica = (target) => {
    yEvent(target);
  };

  const metaTags = {
    title: t('title', { ns: 'login' }),
  };

  const validationSchema = yup.object().shape({
    username: yup
      .string(t('only_text', { ns: 'validate' }))
      .required(t('required', { ns: 'validate' }))
      .nullable(),
    password: yup
      .string(t('only_text', { ns: 'validate' }))
      .required(t('required', { ns: 'validate' }))
      .nullable(),
  });

  return (
    <Auth>
      <MetaTags {...metaTags} />
      {(isLoading) && (
        <div className="loader">
          <div className="bar" />
        </div>
      )}
      <ContentContainer>
        <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <H3>{t('name', { ns: 'login' })}</H3>
            <Help>{t('info', { ns: 'login' })}</Help>
            {resend ? (<ResendActivation onDone={doneResend} email={resend} />) : (
              <Formik
                initialValues={{ username: '', password: '' }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <StyledForm>
                      <Field
                        name="username"
                        label={(<H4>{t('username', { ns: 'login' })}</H4>)}
                        className="form-control-lg"
                        placeholder={t('username_placeholder', { ns: 'login' })}
                        component={ArtField}
                        column
                      />
                      <Field
                        name="password"
                        label={(<H4>{t('password', { ns: 'login' })}</H4>)}
                        className="form-control-lg"
                        placeholder={t('password_placeholder', { ns: 'login' })}
                        type={mainPass ? 'password' : 'text'}
                        component={ArtField}
                        column
                        append={(
                          <Eye
                            type="button"
                            onClick={() => setPass(!mainPass)}
                          >
                            <Icon
                              icon="eye"
                              colorFill="#FFF"
                              width={24}
                              height={12}
                              colorStroke={mainPass ? MAIN_GRAY : ACTIVE}
                              noHover
                            />
                          </Eye>
                        )}
                      />
                      <LinkReset
                        to="/reset"
                      >
                        {t('forgot_password', { ns: 'login' })}
                      </LinkReset>
                    </StyledForm>
                    {/* eslint-disable-next-line max-len */}
                    <div className="d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-start mt-5 p-md-auto">
                      <Button
                        variant="primary"
                        size="xl"
                        onClick={() => metrica('login')}
                        className="col-12 col-md-auto mb-3 mb-md-0 px-5"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? t('await', { ns: 'action' }) : t('login_btn', { ns: 'login' })}
                      </Button>
                      <Authorization>
                        <div>{t('no_account', { ns: 'login' })}</div>
                        <Link
                          className="ms-2"
                          to="/signup"
                          onClick={() => metrica('registration')}
                        >
                          {t('registration', { ns: 'login' })}
                        </Link>
                      </Authorization>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
            <ButtonLogin href={`${config.api}auth/google`}>
              <GoogleIcon src="/images/google-logo.svg" alt="google" />
              {t('google_auth', { ns: 'index' })}
            </ButtonLogin>
          </Col>
        </Row>
      </ContentContainer>
    </Auth>
  );
};

const GoogleIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 20px;
`;

const ButtonLogin = styled.a`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--art-active);
  color: var(--art-dark);
  border-radius: 4px;
  margin-top: 40px;
  &:hover, &:focus {
    text-decoration: none;
    background-color: var(--art-active);
    color: white;
  }
`;

const LinkReset = styled(Link)`
  display: block;
  text-align: right;
  width: 100%;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  &:hover {
    color: rgba(0, 0, 0, 0.5);
    text-decoration: underline;
  }
`;

export default Login;
