/* eslint-disable react/no-unescaped-entities */
import { useMutation } from '@apollo/client';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

import { GQL_RESEND_ACTIVATION } from '../graphql/queries';
import { ACTIVE } from '../helpers/colors';
import { useTranslation } from './hooks/useTranslation';
import InfoBox from './InfoBox';

const ResendActivation = ({ onDone, email }) => {
  const alert = useAlert();
  const { t } = useTranslation();
  const [resendActivation] = useMutation(GQL_RESEND_ACTIVATION);
  const resend = async () => {
    const response = await resendActivation({
      variables: { email },
    });
    const error = _.get(response, 'errors.0', false);
    if (error) {
      alert.show(error.message, { type: 'danger' });
      return false;
    }
    onDone();
    alert.show(t('send_instructions', { ns: 'alerts' }), { type: 'info' });
    return true;
  };
  const text = {
    info: 'Вы уже пытались зарегистрироваться, но не активировали вашу учётную запись.',
    question: 'Что можно сделать',
    first: 'Если вы отправляли несколько минут назад, попробуйте подождать еще 5-10 минут',
    second: `Проверьте правильность написания email «${email}». Если неверно напишите нам об этом через обратную форму с указанием корректного email`,
    third: 'Проверьте, нет ли в почтовом ящике ограничений на получение писем. Например, если ящик переполнен',
    fourth: 'Проверьте в почтовом сервисе папки «СПАМ», «РАССЫЛКИ» или «СОЦСЕТИ»',
    if_not: 'Если рекомендации не помогли и вы всё проверили, попробуйте переотправить письмо с активацией',
    button: 'Переотправить письмо с активацией',
    // eslint-disable-next-line max-len
    bottom: 'В случае, если не получаете письмо  и все пункты выше проверены, пожалуйста, напишите нам об этом в обратной связи суказанием email, который использовался для регистрации',
  };
  return (
    <WarningArea>
      <InfoBox style={{ marginTop: 0, marginBottom: 30 }}>
        <Strong>{text.info}</Strong>
      </InfoBox>
      <Question>
        {text.question}
        {': '}
      </Question>
      <Ul>
        <li>
          <Digit>1</Digit>
          {' '}
          {text.first}
        </li>
        <li>
          <Digit>2</Digit>
          {' '}
          {text.second}
        </li>
        <li>
          <Digit>3</Digit>
          {' '}
          {text.third}
        </li>
        <li>
          <Digit>4</Digit>
          {' '}
          {text.fourth}
        </li>
      </Ul>
      <div>
        {text.if_not}
      </div>
      <ButtonResend type="button" onClick={resend}>
        {text.button}
      </ButtonResend>
      <Bottom>
        {text.bottom}
      </Bottom>
    </WarningArea>
  );
};

const Strong = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
`;

const Question = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 15px;
`;

const Digit = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  background: #F3F3F3;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${ACTIVE};
  margin-right: 10px;
`;

const Bottom = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #E0E5F1;
`;

const WarningArea = styled.div`
  padding: 50px 40px;
  background-color: rgba(255, 255, 255, 0.58);
  margin-top: 20px;
`;

const Ul = styled.ul`
  margin-bottom: 20px;
  list-style: none;
  padding-left: 0;
  li {
    display: inline-flex;
    margin-bottom: 15px;
  }
`;

const ButtonResend = styled.button`
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: ${ACTIVE};
  font-size: 16px;
  border: 1px solid ${ACTIVE};
  color: white;
`;

ResendActivation.defaultProps = {
  onDone: () => {},
  email: '',
};

ResendActivation.propTypes = {
  email: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  onDone: PropTypes.func,
};

export default ResendActivation;
