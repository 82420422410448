import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  Field,
  Form,
  Formik,
} from 'formik';
import { Link, useHistory } from 'react-router-dom';
import ym from 'react-yandex-metrika';
import * as yup from 'yup';
import {
  useMutation, useQuery,
} from '@apollo/client';
import _ from 'lodash';
import { useAlert } from 'react-alert';
import loadable from '@loadable/component';

import { ArtField } from '../components/forms';
import { useTranslation } from '../components/hooks/useTranslation';
import ResendActivation from '../components/ResendActivation';
import {
  GQL_GET_ME,
  GQL_USER_RESET_PASSWORD,
} from '../graphql/queries';
import {
  Auth,
  H3,
  H4,
  Help,
  StyledForm,
} from '../helpers/authStyle';
import { ContentContainer } from '../helpers/mainStyles';

const MetaTags = loadable(() => import('../components/MetaTags'));

const ResetScreen = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const alert = useAlert();
  const [isLoading, setLoading] = useState(false);
  const [sendPassword] = useMutation(GQL_USER_RESET_PASSWORD);
  const [me, setMe] = useState({});
  const [resend, setResend] = useState(false);

  const { data: dataMe } = useQuery(GQL_GET_ME, { fetchPolicy: 'cache-only' });

  useEffect(() => {
    if (_.get(dataMe, 'me._id')) {
      setMe(_.get(dataMe, 'me'));
    }
  }, [dataMe]);

  useEffect(() => {
    ym('hit', '/reset');
  }, []);

  const doneResend = () => {
    setResend(false);
    history.push('/');
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    if (!_.get(values, 'email')) {
      alert.show(t('email_format', { ns: 'validate' }), { type: 'danger' });
      return false;
    }
    try {
      setLoading(true);
      const response = await sendPassword({ variables: { email: `${values.email}`.trim() } });
      const error = _.get(response, 'errors.0', null);
      if (error) {
        if (_.get(error, 'extensions.code') === '401') {
          setResend(_.get(error, 'extensions.exception.email'));
          setLoading(false);
          return false;
        }
        alert.show(_.get(error, 'message'), { type: 'danger' });
        setSubmitting(false);
        setErrors(error.extensions);
        setLoading(false);
        return false;
      }
      history.push('/login');
      alert.show(t('password_cleared', { ns: 'alerts' }), { type: 'info' });
      setLoading(false);
      return true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      alert.show(t('admin_error', { ns: 'alerts' }), { type: 'danger' });
    }
    setLoading(false);
    return true;
  };

  const metaTags = {
    title: t('title', { ns: 'reset' }),
  };

  return (
    <Auth>
      <MetaTags {...metaTags} />
      {(isLoading) && (
      <div className="loader">
        <div className="bar" />
      </div>
      )}
      <ContentContainer>
        <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <H3>{t('name', { ns: 'reset' })}</H3>
            <Help>{t('info', { ns: 'reset' })}</Help>
            {resend ? (<ResendActivation onDone={doneResend} email={resend} />) : (
              <Formik
                initialValues={{ email: _.get(me, 'email') || '' }}
                validationSchema={
                yup.object().shape({
                  email: yup
                    .string(t('only_text', { ns: 'validate' }))
                    .required(t('required', { ns: 'validate' }))
                    .min(5, t('minimum_length', { ns: 'validate', count: 5 }))
                    .max(70, t('maximum_length', { ns: 'validate', count: 70 }))
                    .email(t('email_format', { ns: 'validate' }))
                    .trim()
                    .nullable(),
                })
              }
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <StyledForm>
                      <Field
                        name="email"
                        label={(<H4>{t('email', { ns: 'reset' })}</H4>)}
                        className="form-control-lg"
                        placeholder={t('email_placeholder', { ns: 'reset' })}
                        component={ArtField}
                        column
                      />
                    </StyledForm>

                    <div className="d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between mt-5">
                      <div className="d-flex align-items-center flex-column flex-md-row w-100">
                        <Button
                          variant="primary"
                          size="xl"
                          type="submit"
                          className="col-12 col-md-auto mb-3 mb-md-0"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? t('send_btn', { ns: 'reset' }) : t('btn', { ns: 'reset' })}
                        </Button>
                        <Link
                          className="ms-0 ms-md-5 text-dark mb-3 mb-md-0"
                          to="/signup"
                        >
                          {t('registration', { ns: 'login' })}
                        </Link>
                      </div>
                      <Link
                        className="ms-0 text-nowrap ms-md-5 text-dark mb-3 mb-md-0"
                        to="/login"
                      >
                        {t('login_btn', { ns: 'login' })}
                      </Link>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </Col>
        </Row>
      </ContentContainer>
    </Auth>
  );
};

export default ResetScreen;
