import styled, { css } from 'styled-components';
import { USER_AMATEUR } from './main';
import { ACTIVE } from './colors';

export const Authorization = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  a {
    font-weight: 500;
    color: ${ACTIVE};
  }
  @media screen and (max-width: 700px) {
    margin-top: 10px;
    margin-left: 0;
  }
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

export const Auth = styled.div`
  margin-top: 3rem;
  margin-bottom: 4rem;
  @media screen and (max-width: 700px) {
    overflow: hidden;
  }
`;

export const H2 = styled.h2`
  font-weight: bold;
  font-size: 28px;
  line-height: 120%;
  margin-top: 50px;
  @media screen and (max-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 700px) {
    font-size: 16px;
  }
`;

export const H3 = styled.h3`
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 0;
  line-height: 100%;
  @media screen and (max-width: 1024px) {
    font-size: 32px;
  }
  @media screen and (max-width: 700px) {
    font-size: 24px;
  }
`;

export const H4 = styled.h4`
  font-weight: bold;
  font-size: 26px;
  line-height: 120%;
  @media screen and (max-width: 1024px) {
    font-size: 18px;
    line-height: 20px;
  }
  @media screen and (max-width: 700px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const Help = styled.div`
  font-size: 14px;
  @media screen and (max-width: 700px) {
    font-size: 12px;
  }
`;

export const StyledForm = styled.div`
  margin-top: 23px;
  background-color: white;
  padding: 60px 50px;
  .PhoneInputInput {
    flex: 1;
    min-width: 0;
    border: 0;
    padding: 18px 15px;
  }
  @media screen and (max-width: 700px) {
    padding: 20px;
  }
`;

export const HelperName = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

export const Relative = styled.div`
  position: relative;
`;

export const ImageWrap = styled.div`
  width: 100px;
  height: 70px;
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

export const Image = styled.img`
  display: ${({ visibility }) => (visibility ? 'block' : 'none')};
  height: 110px;
  top: ${({ role }) => (role === USER_AMATEUR ? -40 : 0)}px;
  right: -40px;
  position: absolute;
  @media screen and (max-width: 812px) {
    position: static;
    height: 60px;
  }
`;

export const FormLabel = styled.label`
  display: flex;
  margin-bottom: 50px;
`;

export const Choice = styled.div`
  display: block;
  margin-left: 20px;
  flex: 1;
  @media screen and (max-width: 812px) {
    margin-left: 10px;
  }
`;

export const ChoiceRole = styled.div`
  font-weight: bold;
  font-size: 26px;
  line-height: 120%;
  @media screen and (max-width: 812px) {
    font-size: 16px;
  }
`;

export const ChoiceInfo = styled.div`
  font-size: 20px;
  margin-top: 10px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.5);
  @media screen and (max-width: 812px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const RoleGroup = styled.div`
  margin-top: 2rem;
  padding: 4rem;
  background-color: white;
  @media screen and (max-width: 812px) {
    padding: 30px 20px;
  }
`;

export const Eye = styled.button`
  padding: 0;
  margin-left: -5px;
  position: relative;
  z-index: 5;
`;

const pass = css`
  z-index: 40;
  position: absolute;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  top: 120px;
  @media screen and (max-width: 812px) {
    font-size: 12px;
    p {
      padding: 0;
    }
  }
`;

export const HelperBlock = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  ${pass};
`;

export const HelperBlockRepeat = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  ${pass};
`;
