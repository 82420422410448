import { ResponsiveMasonry } from 'react-responsive-masonry';
import styled from 'styled-components';

export const ResponsiveMasonryStyle = styled(ResponsiveMasonry)`
  // hack for safari 10+ > react-responsive-masonry
  // Look if fixed grid and flex
  position: relative;
  min-height: 100vh;
  &:before {
    content: ${({ loading }) => (loading ? '""' : 'none')};
    position: absolute;
    z-index: 1000;
    display: block;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    cursor: progress;
    background-color: rgba(255, 255, 255, 0.31);
  }

  @supports (-webkit-hyphens: none) {
    > div > div {
      > div {
        margin-bottom: 30px;
      }

      &:first-child {
        margin-right: ${({ view, gutter }) => (view === 'th' && gutter ? gutter : 0)}px;
      }

      &:last-child {
        margin-left: ${({ view, gutter }) => (view === 'th' && gutter ? gutter : 0)}px;
      }
    }

    @media screen and (max-width: 1024px) {
      > div > div {
        &:first-child {
          margin-right: ${({ view, gutter }) => (view === 'th' && gutter ? gutter / 2 : 0)}px;
        }

        &:last-child {
          margin-left: ${({ view, gutter }) => (view === 'th' && gutter ? gutter / 2 : 0)}px;
        }
      }
    }
    @media screen and (max-width: 750px) {
      > div > div {
        &:first-child {
          margin-right: 0;
        }

        &:last-child {
          margin-left: 0;
        }
      }
    }
  }
`;

export const ContentContainer = styled.div`
  @media screen and (max-width: 812px) {
    padding: 0 15px;
  }
`;

export const Status = styled.div`
  font-size: 10px;
  line-height: 120%;
  color: #9291b2;
`;

export const Color = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  margin-top: 5px;
  white-space: pre;
  color: ${({ color }) => color};
  @media screen and (max-width: 320px) {
    font-size: 10px;
    line-height: 100%;
  }
`;

export const H1 = styled.h1`
  font-weight: bold;
  line-height: 120%;
  font-size: 40px;
  margin-bottom: ${({ bottom }) => (bottom ? 50 : 0)}px;
  @media screen and (max-width: 1024px) {
    font-size: 24px;
    padding: 10px 0 0;
  }
  @media screen and (max-width: 812px) {
    font-size: 24px;
    padding: 10px 15px 0;
    margin-bottom: 50px;
    text-align: center;
  }
  @media screen and (max-width: 500px) {
    padding: 0;
    margin-bottom: 25px;
  }
`;

export const H2 = styled.h2`
  font-weight: bold;
  font-size: 28px;
  line-height: 120%;
  margin-top: 50px;
  @media screen and (max-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 700px) {
    font-size: 16px;
  }
`;
