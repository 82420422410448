import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';

import { ACTIVE, MAIN_GRAY } from '../helpers/colors';
import Icon from './Icon';

export const WARNING_INFO = 'warning';
export const DANGER_INFO = 'danger';
export const SUCCESS_INFO = 'success';
export const DEFAULT_INFO = 'info';

/**
 * @constructor
 * @param {String} status Type of info box: 'warning', 'danger', 'success';
 * @param {Boolean|Object} style JS styles for box
 * @param {JSX.Element|string} children Any text or component
 * @return {JSX.Element|string}
 * @example
 * <InfoBox>
 *    Some text
 * </InfoBox>
 */
const InfoBox = ({ status, style, children }) => {
  let bgColor;
  let iconColor;
  switch (status) {
    case DANGER_INFO:
      iconColor = '#F6532D';
      bgColor = '#F6532D33';
      break;
    case WARNING_INFO:
      iconColor = '#ff7e24';
      bgColor = '#ffddab';
      break;
    case SUCCESS_INFO:
      iconColor = '#40b603';
      bgColor = '#cbfcca';
      break;
    default:
      iconColor = ACTIVE;
      bgColor = MAIN_GRAY;
      break;
  }
  return (
    <Box color={bgColor} style={style || {}}>
      <Icon noHover icon="info" width={24} height={24} colorStroke={iconColor} />
      <Text>
        {children}
      </Text>
    </Box>
  );
};
const Box = styled.div`
  display: flex;
  align-items: flex-start;
  background-color: ${({ color }) => color};
  padding: 30px;
  margin-bottom: 30px;
  margin-top: 50px;
  @media screen and (max-width: 1024px) {
    padding: 25px;
  }
  @media screen and (max-width: 600px) {
    padding: 15px;
    flex-direction: column;
  }
`;

const Text = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 18px;
    p {
      font-weight: 400;
      font-size: 18px;
    }
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
    p {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 500px) {
    margin-left: 0;
    margin-top: 10px;
    font-size: 14px;
    line-height: 120%;
    p {
      font-size: 14px;
      line-height: 120%;
    }
  }
`;

InfoBox.defaultProps = {
  status: DEFAULT_INFO,
  style: false,
};

InfoBox.propTypes = {
  status: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
  children: PropTypes.any.isRequired,
};

export default InfoBox;
